<template>
  <div  >
       <b-navbar toggleable="lg" type="dark"  position="top"  class="bgtopbar"  >
        <b-navbar-brand href="#" v-on:click="goback()"> Back </b-navbar-brand>
    </b-navbar>
    <div class="d-flex justify-content-center" md="6" >
    <b-card border-variant="dark" header="Error" align="center">
        <b-card-text>Page Not Found</b-card-text>
      </b-card>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  data() {
    return {

    }
  },
  methods: {
        goback() {
            this.$router.go(-1)
        }
  }
};
</script>